export default {
  CUSTOMERS_LIST: "Customers list",
  ADD_CUSTOMER: "Add customer",
  ADD_CONTACT: "Add contact",
  CUSTOMER_ADDED: "Customer added",
  EDIT_CUSTOMER: "Edit customer",
  CUSTOMER_UPDATED: "Customer updated",
  DELETE_THIS_CUSTOMER: "Delete this customer?",
  CUSTOMER_DELETED: "Customer deleted",
  BILLING_INFORMATIONS_SAME_AS_CUSTOMER: "Same as customer's address",
  CUSTOMER_TYPE_INDIVIDUAL: "Individual",
  CUSTOMER_TYPE_COMPANY: "Company",
};