export default {
  PAYMENTS_LIST: "Payments List",
  ADD_PAYMENT: "Add Payment",
  PAYMENT_ADDED: "Payment Added",
  AUTHORIZATIONS: "Authorizations",
  EDIT_PAYMENT: "Edit Payment",
  PAYMENT_SOURCE_MANUAL: "Manual",
  PAYMENT_SOURCE_PAYPAL: "Paypal",
  PAYMENT_SOURCE_STRIPE: "Stripe",
  SOURCE: "Source",
  TRANSACTION_ID: "Transaction ID",
  TRANSACTION_DATA: "Transaction Data",
  SOURCE_DETAILS: "Payment Details",
};